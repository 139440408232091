import env from 'config'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import { Alert, Grid } from '@mui/material'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Loader from 'examples/Loader'
// import { FormGroup, FormLabel, TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
// import moment from 'moment'
import './style.css'

import { useEffect, useState } from 'react'
import { useAgencyInfo } from 'context/agency'
import { useAppServices } from 'hook/services'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import MDBox from 'components/MDBox'
function Default() {
  const [agency] = useAgencyInfo()
  const Service = useAppServices()
  const [Conjo, setConjo] = useState([])
  const [loader, setloader] = useState(true)

  const columns = [
    {
      name: 'Location ID',
      options: {
        filter: false,
      },
    },
    {
      name: 'Location Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'User Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'Email',
      options: {
        filter: false,
      },
    },
    {
      name: 'api_key',
      options: {
        filter: false,
      },
    },
    {
      name: 'Created At',
      options: {
        filter: false,
      },
    },
    {
      name: 'Action',
      options: {
        filter: false,
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'standard',
  }
  const handleDelete = async (location) => {
    const { response } = await Service.sso.delete({
      query: `_id=${location._id}`,
    })
     

    getLocations()
  }
  const date_format = (date) => {
    const dateObject = new Date(date);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const formattedDate = dateObject.toLocaleString(undefined, options);
    return formattedDate
  }
  const getLocations = async () => {
    const { response } = await Service.sso.getlocations()
    if (response) {
      const uniqueEmails = [];
      const tabel_data = response?.data.map((element) => {
        // Check if the email is already added to uniqueEmails array
        if (!uniqueEmails.includes(element.email)) {
          uniqueEmails.push(element.email);
          return [
            element.location_id,
            element.loc_name,
            element.login,
            element.email,
            element.api_key,
            date_format(element.createdAt),
            <button className='btn btn-danger' onClick={() => handleDelete(element)}>delete</button>,
          ];
        }
        return null;
      });

      // Filter out null entries (skipped duplicates)
      setConjo(tabel_data.filter(entry => entry !== null));
      setloader(false);
    } else {
      setloader(false);
    }
  }
  const onLoad = () => {
    // alert('s')
    getLocations()
  }
  useEffect(async () => {
    onLoad()
  }, [])
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <DashboardLayout>
          <DashboardNavbar
            type="location_prospective"
            handleChange={''}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {console.log(Conjo)}
              <MUIDataTable title={''} data={Conjo} columns={columns} options={options} />
            </Grid>
          </Grid>
        </DashboardLayout>
      )}
    </>
  )
}

export default Default
