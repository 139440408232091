import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useAgencyInfo } from "context/agency";
import Multiselect from './components/Multiselect'
import { TextareaAutosize, Tooltip } from '@mui/material'
import infoimg from "../info.png"
import ReactSelect from 'react-select'
import ModelPromptslabels from './components/createtag'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 550,
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  p: 4,
  overflow: "hidden",
}
const DocsValidate = yup.object({
  name: yup.string().required().label('Name'),
})
function OptionCreate({ open, onClose, options, prices }) {
  const [agency, Update] = useAgencyInfo();
  const uploadImage = useUploadImage()
  const [processing, setProcessing] = useState(false)
  const AppService = useAppServices()

  const [contentCreativity, setContentCreativity] = useState(2);
  const [btgen, setBtgen] = useState(1);
  const [modalName, setModalName] = useState([]);
  const [tags, setTags] = useState([]);
  const [modaloption, setmodaloption] = useState([]);
  const [tagsoption, settagsoption] = useState([]);
  const [addTagModel, setAddTagModel] = useState(false);
  const [payloadodpendora, setpayloadodpendora] = useState({
    instructions: '',
    tools: [],
    temperature: '',
    top_p: '',
    model: '',
    tagsa: [],
    agency_id: '',
    profile_id: "",
    name: "",
    template_id: ""
  })
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true)
    const create_payload = {
      assistant_name: e.target.assistant_name.value,
      purpose: e.target.purpose.value, // Fix typo
      // modal_name: modalName,
      modal_name: "openai/gpt-4o",
      content_creativity: contentCreativity, // Correct field name
      btgen: btgen,
      prompt_label_ids: tags,
      agency_id: agency._id,
      profile_id: 'superadmin',
      type: "super-admin",
      readonly: e.target.readonly ? true : false,
    };
    const update_payload = {
      assistant_name: e.target.assistant_name.value,
      purpose: e.target.purpose.value, // Fix typo
      // modal_name: modalName,
      modal_name: "openai/gpt-4o",
      content_creativity: contentCreativity, // Correct field name
      btgen: btgen,
      prompt_label_ids: tags,
      agency_id: agency._id,
      profile_id: 'superadmin',
      type: "super-admin",
      readonly: e.target.readonly ? true : false,
      _id: options._id
    };
    const payload = options._id ? update_payload : create_payload;
    const newAssistant = await AppService.assistant[
      options?._id ? 'update' : 'create'
    ]({
      payload,
      toaster: true,
    })
    if (newAssistant) {
      setProcessing(false)
      onClose()
      // runAPIofStoreAsisstant(payload);
    }
    // Submit logic here, e.g., calling API with AppService
  };
  const handleChangeTags = (selectedOptions) => {
    setTags(selectedOptions ? selectedOptions?.map(option => option.value) : []);
    setpayloadodpendora({
      ...payloadodpendora,
      tagsa: selectedOptions ? selectedOptions?.map(option => option.label) : []
    });
  };

  const handleCCInputChange = (event) => {
    const tempSliderValue = event.target.value;
    const progress = (tempSliderValue / event.target.max) * 100;

    event.target.style.background = `linear-gradient(to right, gray ${progress}%, #ccc ${progress}%)`;

    setContentCreativity(tempSliderValue); // Set actual value, not progress percentage
  };

  const handleBtInputChange = (event) => {
    const tempSliderValue = event.target.value;
    const progress = (tempSliderValue / event.target.max) * 100;
    event.target.style.background = `linear-gradient(to right, gray ${progress}%, #ccc ${progress}%)`;

    setBtgen(tempSliderValue); // Set actual value, not progress percentage
  };
  const GetModels = async () => {
    const { response } = await AppService.assistant.Get();
    if (response) {
      setmodaloption(response.data)
    }
  }
  const promptslabel = async () => {
    const { response } = await AppService.promptslabel.Get({
      query: `type=super-admin`,
    });
    if (response) {
      settagsoption(response.data)

    }
  }
  useEffect(() => {
    GetModels(); promptslabel();
  }, [])
  const runAPIofStoreAsisstant = async (payload) => {
    console.log('payloadpayload', payload)
    const data = {
      instructions: payload.purpose,
      tools: ["file_search"],
      temperature: 2,
      top_p: 2,
      // temperature: parseInt(btgen),
      // top_p: parseInt(contentCreativity),
      model: payload.modal_name,
      tags: payloadodpendora.tagsa,
      agency_id: "foo",
      profile_id: 'superadmin',
      name: payload.assistant_name,
      template_id: "Qdl1CPWly",
      readonly: payload.readonly
    };

    axios.post('https://pandora.maybetech.com/api/assistants/custom', data, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer a71860da-1a58-4797-90f1-02dcb5fd06b1`,
      }
    })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }
  const [checkbox, setcheckbox] = useState(false)
  const handlechecbox = () => {
    setcheckbox(!checkbox)
  }

  const savedataPromptLabel = async (formData) => {
    const updatedFormData = {
      ...formData,
      agency_id: agency._id,
      type: "super-admin"
    };
    const payload = updatedFormData;

    const { response } = await AppService.promptslabel.create({
      payload,
      toaster: true,
    });
    if (response) {
      handleclose();
      setAddTagModel(false);
      promptslabel();
    }

  }
  const handleclose = () => {
    setAddTagModel(false);
  }
  const handleAddTag = () => {
    setAddTagModel(true);
  }
  return (
    <MDBox>
      <MDModal
        open={open}
        onClose={onClose}
        height={550}
        width={500}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
          <MDTypography variant="h6">Create Assistant</MDTypography>


          <MDBox my={1}>
            <label className="text-black" style={{ display: "flex", alignItems: "center" }} >
              <span style={{ fontSize: "14px" }}>Assistant Name</span> </label>

            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="text" style={{ width: "100%" }} name='assistant_name' defaultValue={options?.assistant_name} />

            </div>
          </MDBox>

          <MDBox my={1}>
            <label style={{ fontSize: "14px" }}>Porpose</label>
            <TextareaAutosize
              style={{ width: "100%", height: "10vh" }}
              type="text"
              label="purpose"
              name="purpose"
              defaultValue={options?.purpose}
              fullWidth
            />
          </MDBox>

          <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
            <MDBox my={1}>
              <label className="text-black" style={{ display: "flex", alignItems: "center" }}><span style={{ fontSize: "14px" }}>Content Creativity</span> </label>

              <div style={{ display: "flex", alignItems: "center" }}>
                <input type="range" min="0" max="2" step={"0.01"} onChange={handleCCInputChange} className="range-input" />

                <input
                  type="number"
                  value={contentCreativity}
                  min="0"
                  max="2"
                  step="0.01"
                  onChange={(event) => handleCCInputChange(event)}
                  style={{
                    maxWidth: "50px",
                    fontSize: "14px",
                    margin: "2px",
                    padding: "0px 2px",
                    borderRadius: "5px",
                    border: "1px solid Black"
                  }}
                  className="height_25 bg_transparent removeControl"
                />

              </div>
            </MDBox>

            <MDBox my={1}>
              <label className="text-black" style={{ display: "flex", alignItems: "center" }} ><span style={{ fontSize: "14px" }}>Balance Text Generation</span> </label>

              <div style={{ display: "flex", alignItems: "center" }}>
                <input type="range" min="0" max="1" step={"0.01"} onChange={handleBtInputChange} className="range-input" />

                <input
                  type="number"
                  value={btgen}
                  min="0"
                  max="1"
                  step="0.01"
                  onChange={(event) => handleBtInputChange(event)}
                  style={{
                    maxWidth: "50px",
                    fontSize: "14px",
                    margin: "2px",
                    padding: "0px 2px",
                    borderRadius: "5px",
                    border: "1px solid Black"
                  }}
                  className="height_25 bg_transparent"
                />

              </div>
            </MDBox>
          </MDBox>

          <MDBox my={1}>
            <MDTypography className="text-black w-100 d-flex justify-content-between" variant="p" sx={{ fontSize: "14px", fontWeight: "500", alignItems: 'center' }} mb={1}>
              <label>
                Tags*
              </label>
              <p style={{ cursor: 'pointer', textAlign: 'right', margin: 0 }} onClick={handleAddTag}>
                + Add Tag
              </p>
            </MDTypography>
            <ReactSelect
              name='tags'
              className="form-input w-full"
              options={Array.isArray(tagsoption) ? tagsoption.map((item) => ({
                label: item.name,
                value: item._id
              })) : []}
              isMulti={true}
              onChange={handleChangeTags}
            />
          </MDBox>
          <MDBox mt={2}>
            <label className="text-black" style={{ display: "flex", alignItems: "center", cursor: "pointer" }} >
              <input type="checkbox" name="readonly" onChange={handlechecbox} style={{ margin: "0px 5px" }} /> <span style={{ fontSize: "14px" }}>Agency Only</span></label>

          </MDBox>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            className="brand-card-background"
            loading={processing}
            disabled={processing}
            sx={{ mt: 1, mb: 1 }}
            fullWidth
          >
            {options?.name ? 'Update' : 'Save'}
          </MDButton>
        </MDBox>
      </MDModal>
      {
        addTagModel && (
          <ModelPromptslabels data={null} modelfunctionality={savedataPromptLabel} openModel={addTagModel} onclosemodel={handleclose} />
        )
      }
    </MDBox>
  )
}

export default OptionCreate
